
import { Options, Vue } from 'vue-class-component'
import PlanStepDeleteCard from '@/components/PlanStepDeleteCard.vue'
import service from '@/utils/request'
import { ApplyStatus, Plan as PlanModel, PlanStatus, Task } from '@/models/plan'
import { AuthStore } from '@/store/modules/auth'
import { Dialog, Toast } from 'vant'
import NaviBar from '@/components/NaviBar.vue'

@Options({
  components: {
    PlanStepDeleteCard,
    NaviBar
  }
})
export default class PracticeDailyList extends Vue {
  applyStatus = ApplyStatus
  planStatus = PlanStatus
  authStore = AuthStore
  dailyTasks: Task[] = []

  mounted () {
    this.loadDailyTask()
  }

  loadDailyTask () {
    service.get('/mindup/mini/tasks/daily-task/list', {}).then(res => {
      this.dailyTasks = res.data
    })
  }

  cancelDiary (index: number) {
    Dialog.confirm({
      title: '取消此每日练习',
      message: '移除后，此项自定义每日练习将不再出现在每日练习计划中。',
      confirmButtonText: '取消练习'
    })
      .then(() => {
        service.post('/mindup/mini/tasks/' + this.dailyTasks[index].id + '/cancel-daily-practice', {}).then(res => {
          Toast.success('取消成功')
          this.loadDailyTask()
        })
      })
      .catch(() => {
        // on cancel
      })
  }

  tapAdd () {
    this.$router.push({ name: 'PlanList' })
  }
}
